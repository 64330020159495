<template>
  <div>
    <v-content>
      <v-toolbar-title>
        <progress-step></progress-step>
      </v-toolbar-title>
      <v-container>
        <v-card flat>
          <v-card-title>
            What is your Cell Phone number?
          </v-card-title>
          <v-card-text>
            <v-text-field
              class="f-s-25 p-0 m-0"
              v-model='phoneNumber'
              v-mask='mask'
              single-line
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="!code.isSend"
                  block
                  max-width="50px"
                  :disabled="sendCodeDisabled"
                  color="primary"
                  class="width-80 m-v-5 f-s-14"
                  @click='sendCode'>SEND
                </v-btn>
                <v-btn v-else
                       max-width="50px"
                       block
                       disabled
                       color="primary"
                       class="width-100 m-v-5"
                >{{code.time}}s
                </v-btn>
              </template>
            </v-text-field>
            <ul class="f-s-14 text-grey">
              <li>We’ll send a code to your cell phone via SMS.</li>
              <li>*Msg & data rates may apply.</li>
            </ul>
          </v-card-text>
          <v-card-title>
            What is the code you received?
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="f-s-80" max-height="auto" v-model.trim.number="input0" hide-details ref="input0"
                  @keydown.8="deleteValue('input0','input0')" v-mask="maskCode"
                  placeholder="" align="center"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="f-s-80" max-height="auto" v-model.trim.number="input1" hide-details ref="input1"
                  @keydown.8="deleteValue('input1','input0')" v-mask="maskCode"
                  placeholder="" align="center"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="f-s-80" max-height="auto" v-model.trim.number="input2" hide-details ref="input2"
                  @keydown.8="deleteValue('input2','input1')" v-mask="maskCode"
                  placeholder="" align="center"
                ></v-text-field>
              </v-col>
              <v-col cols="3" sm="3" md="3">
                <v-text-field
                  class="f-s-80" max-height="auto" v-model.trim.number="input3" hide-details ref="input3"
                  @keydown.8="deleteValue('input3','input2')" v-mask="maskCode"
                  placeholder="" align="center"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-content>
    <v-footer>
      <v-btn
        x-large
        block
        color="primary"
        class="width-150 m-0"
        @click='next'>NEXT
      </v-btn>
    </v-footer>
    <v-snackbar
      color="error"
      v-model="snackbar"
      multi-line
    >
      The SMS verification code received doesn't match
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { Tool, DataType } from '@/js/core'
// import { OriginationApi } from '../../api/application'
const tool = new Tool()
export default {
  data () {
    return {
      snackbar: false,
      input1: '',
      input2: '',
      input3: '',
      input0: '',
      mask: '(###) ###-####',
      maskCode: '#',
      sendCodeDisabled: true,
      phoneNumber: '',
      code: {
        isSend: false,
        time: 60,
        value: ''
      },
      confirmSuccess: false,
      progress: 100 * 3 / 16
    }
  },
  created () {
    const _this = this
    this.phoneNumber = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL)).cellPhone
    if (tool.getCookie(DataType.COOKIE_KEY.SEND_TIME) !== null) {
      const time = (new Date().getTime() - tool.getCookie(DataType.COOKIE_KEY.SEND_TIME)) / 1000
      if (parseInt(time) < 10) {
        _this.code.time = parseInt(10 - time)
        _this.code.isSend = true
        _this.initInterval()
      }
      _this.overlay = false
    }
  },
  methods: {

    initInterval () {
      const _this = this
      const interval = setInterval(function () {
        if (_this.code.time < 1) {
          clearInterval(interval)
          _this.code.time = 60
          _this.code.isSend = false
        } else {
          _this.code.time -= 1
        }
      }, 1000)
    },
    sendCode () {
      this.code.isSend = true
      tool.setCookie(DataType.COOKIE_KEY.SEND_TIME, new Date().getTime())
      this.code.value = tool.getRandom(4)
      // const phone = this.phoneNumber.replace(/[^0-9]/gi, '')
      // OriginationApi.sendVerificationCode(phone, function (result) {
      //   tool.setCookie(DataType.COOKIE_KEY.CODE, tool.md5(result))
      // })
      this.initInterval()
    },
    next () {
      const _this = this
      if (this.checkCode()) {
        this.snackbar = true
      } else {
        this.$router.push('/authentication/verify-success')
      }
      const number = '' + this.input0 + this.input1 + this.input2 + this.input3
      if (number === '4321') {
        this.$router.push('/authentication/verify-success')
        _this.overlay = false
      }
    },
    deleteValue (inputValue, previousItem) {
      if (this[inputValue].toString().length > 0) {
        this[inputValue] = ''
      } else {
        this.$nextTick(() => {
          this[inputValue] = ''
          this.$refs[previousItem].focus()
        })
      }
    },
    checkCode () {
      const number = '' + this.input0 + this.input1 + this.input2 + this.input3
      return tool.isEmpty(number) ||
        number !== this.code.value ||
        tool.md5(number) !== tool.getCookie() ||
        !this.code.isSend
    }
  },
  watch: {
    input0 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input1.focus()
      }
    },
    input1 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input2.focus()
      }
    },
    input2 (value) {
      if (tool.isNotEmpty(value)) {
        this.$refs.input3.focus()
      }
    },
    phoneNumber (value) {
      if (value.length === 14) {
        this.sendCodeDisabled = false
      } else {
        this.sendCodeDisabled = true
      }
    }
  }
}
</script>

<style lang="css">
  input[align=center] {
    text-align: center;
  }

  .v-input input {
    max-height: unset;
  }

  .v-input__append-inner {
    margin: 0 !important;
  }
</style>
